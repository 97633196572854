import Feature from './Feature'
import s from './Features.module.scss'

export default function Features({ header, features }) {
  return (
    <section id="features" className={s.features}>
      <h2 className={s.header}>{header}</h2>
      <div className={s.featuresContainer}>
        {
          features.map((feature, i) => (
            <Feature
              key={i}
              title={feature.title}
              text={feature.text}
              img={feature.img}
            />
          ))
        }
      </div>
    </section>
  )
}
