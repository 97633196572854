import content from '../pageContent/homePageContent.json'
import sharedContent from '../pageContent/sharedContent.json'
import Nav from '../components/shared/nav/Nav'
import Welcome from '../components/home/welcome/Welcome'
import About from '../components/home/about/About'
import Gallery from '../components/home/gallery/Gallery'
import Instructions from '../components/home/instructions/Instructions'
import Features from '../components/home/features/Features'
import Downloads from '../components/home/downloads/Downloads'
import Requirements from '../components/home/requirements/Requirements'
// import Statistics from '../components/home/statistics/Statistics'
import Reviews from '../components/home/reviews/Reviews'
import News from '../components/home/news/News'

export default function Home() {
  return (
    <>
      <Nav
        icon={sharedContent.iconImg}
        title={sharedContent.title}
        subtitle={sharedContent.subtitle}
        buttons={sharedContent.nav.buttons}
        sections={[
          { id: 'about', text: 'About' },
          { id: 'instructions', text: 'Instruction' },
          { id: 'gallery', text: 'Gallery' },
          { id: 'features', text: 'Features' },
          { id: 'downloads', text: 'Downloads' },
          { id: 'requirements', text: 'Requirements' },
          // { id: 'statistics', text: 'Statistics' },
          { id: 'reviews', text: 'Reviews' },
          { id: 'news', text: 'News' },
        ]}
      />
      <Welcome
        header={content.welcomeSection.header}
        description={content.welcomeSection.description}
        images={content.welcomeSection.imgs}
      />
      <About
        header={content.aboutSection.header}
        text={content.aboutSection.text}
      />
      <Gallery images={content.gallerySection.imgs} />
      <Instructions
        header={content.instructionsSection.header}
        videoUrl={content.instructionsSection.videoUrl}
        primaryText={content.instructionsSection.primaryText}
        secondaryText={content.instructionsSection.secondaryText}
        manualLink={content.instructionsSection.manualLink}
      />
      <Features
        header={content.featuresSection.header}
        features={content.featuresSection.features}
      />
      <Downloads
        header={content.downloadsSection.header}
        downloads={content.downloadsSection.downloads}
      />
      <Requirements
        header={content.requirementsSection.header}
        requirements={content.requirementsSection.requirements}
      />

{/* <Statistics
        header={content.statisticsSection.header}
        statistics={content.statisticsSection.statistics}
      /> */}

      <Reviews
        header={content.reviewsSection.header}
        reviews={content.reviewsSection.reviews}
      />

      <News
        header={content.newsSection.header}
        news={content.newsSection.news}
      />
    </>
  )
}
