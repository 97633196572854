import s from './About.module.scss';

export default function About({
  header, text
}) {
  return (
    <section id="about" className={s.about}>
      <div className={s.container}>
        <h2 className={s.header}>{header}</h2>
        <p className={s.text}>{text}</p>
      </div>
    </section>
  )
}
