import { useWindowWidth } from '@react-hook/window-size'
import s from './Feature.module.scss'

export default function Feature({ title, text, img }) {
  const windowWidth = useWindowWidth()

  return (
    <div className={s.feature}>
      <div className={s.imgHolder}>
        <img className={s.img} src={require('../../../pageContent/img/' + img)} alt="Loading..." />
      </div>
      <h3 className={s.title}>{title}</h3>
      {
        windowWidth >= 1280 && (
          <p className={s.text}>{text}</p>
        )
      }
    </div>
  )
}
