import s from './Welcome.module.scss'
import ImageGallery from '../../shared/imageGallery/ImageGallery.js';

export default function Welcome({ header, description, images }) {
  return (
    <section id="welcome" className={s.welcome}>
      <div className={s.container}>
        <div className={s.headerDesc}>
          <h1 className={s.header}>{header}</h1>
          <p className={s.description}>{description}</p>
        </div>
        <div className={s.images}>
          <ImageGallery images={images} width="800px" height="550px" />
        </div>
      </div>
    </section>
  )
}