import ImageGallery from '../../shared/imageGallery/ImageGallery.js';
import s from './Gallery.module.scss';

export default function Gallery({ images }) {
  return (
    <section id="gallery" className={s.gallery}>
      <div className={s.galleryContainer}>
        <ImageGallery images={images} />
      </div>
    </section>
  );
}
