import s from './Requirements.module.scss'

export default function Requirements({ header, requirements }) {
  return (
    <section id="requirements" className={s.requirements}>
      <h2 className={s.header}>{header}</h2>
      <div className={s.requirementsContainer}>
        {requirements.map((requirement, i) => (
          <div key={i} className={s.requirement}>
            {'+ ' + requirement}
          </div>
        ))}
      </div>
    </section>
  )
}
