import { useWindowWidth } from '@react-hook/window-size'
import React, { useState, useRef, useEffect } from 'react';
import navIcon from '../../../assets/icons/nav.svg'
import s from './Nav.module.scss'
import ScrollProgressIndicator from '../scroll/ScrollProgressIndicator';

export default function Nav({ icon, title, subtitle, buttons, sections }) {
  const windowWidth = useWindowWidth()
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const menuRef = useRef();
  const [showScrollProgress, setShowScrollProgress] = useState(false);
  const [showNavContainer, setShowNavContainer] = useState(false);
  const [isProgressBarVisible, setIsProgressBarVisible] = useState(true);
  const [currentSectionId, setCurrentSectionId] = useState(sections[0].id);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowNavContainer(true);
      } else {
        setShowNavContainer(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [sections]);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowScrollProgress(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  const scrollToNextSection = () => {
    const nextSectionId = findNextSectionId();
    setCurrentSectionId(nextSectionId);
    const nextSection = document.getElementById(nextSectionId);
    nextSection.scrollIntoView({ behavior: 'smooth' });
  };

  const findNextSectionId = () => {
    const currentIndex = sections.findIndex(section => section.id === currentSectionId);
    const nextIndex = (currentIndex + 1) % sections.length;
    return sections[nextIndex].id;
  };

  const goToSection = (isExternalLink, link) => {
    if (isExternalLink) {
      window.open(link, '_blank')
    } else {
      const section = document.getElementById(link)
      section.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuVisible(false);
    }
  };

  const toggleProgressBarVisibility = () => {
    setIsProgressBarVisible(!isProgressBarVisible);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1280) {
        setIsMenuVisible(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const checkScroll = () => {
      if (window.scrollY > 500) {
        setShowScrollProgress(true);
      } else {
        setShowScrollProgress(false);
      }
    };

    window.addEventListener('scroll', checkScroll);

    return () => {
      window.removeEventListener('scroll', checkScroll);
    };
  }, []);

  return (
    <>
      {/* <div className={`${s.navContainer} ${showNavContainer ? s.visible : ''}`}></div> */}
      <div className={`${s.navContainer} ${s.visible}`}>
      </div>
      <div className={s.nav}>
        {/* Conditional rendering based on isProgressBarVisible state */}
        {isProgressBarVisible && showScrollProgress && windowWidth >= 1280 && (
          <ScrollProgressIndicator sections={sections} fadeIn={true} />
        )}
        <div className={s.iconTitle}>
          {/* Wrap the image with a button to toggle the ScrollProgressIndicator */}
          <button onClick={toggleProgressBarVisibility} className={s.iconButton}>
            <img className={s.icon} src={require('../../../pageContent/img/' + icon)} alt="Navigation Icon" />
          </button>

          <div className={s.titleSubtitle}>
            <h1 className={s.title}>{title}</h1>
            <p className={s.subtitle}>{subtitle}</p>
          </div>
        </div>

        {windowWidth >= 1280 ? (
          <div className={s.buttons}>
            {buttons.map((button, i) => (
              <div key={i} className={s.button} onClick={() => goToSection(button.isExternalLink, button.link)}>
                {button.text}
              </div>
            ))}
          </div>
        ) : (
          <button onClick={toggleMenu} className={s.navIconButton}>
            <img className={s.navIcon} src={navIcon} alt='Navigation Icon' />
          </button>
        )}
        {isMenuVisible && (
          <div className={s.menuContainer}>
            <div className={s.menu} ref={menuRef}>
              <div className={s.menuTitle}>Menu</div>
              <div className={s.menuItem}>Video</div>
              <div className={s.menuItem}>Forum</div>
              <div className={s.menuItem}>Terms</div>
            </div>
          </div>
        )}
      </div>

      <button className={s.fixedButton} onClick={scrollToNextSection}>
        Learn More
      </button>
    </>
  )
}
