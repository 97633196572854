
import s from './Reviews.module.scss';

export default function Reviews({ header, reviews }) {
  return (
    <section className={s.reviews} id="reviews">
      {reviews && reviews.length > 0 && (
        <ul className={s.reviewsSection}>
          {reviews.map((item, index) => (
            <li key={index} className={s.blueBox}>
              <div className={s.whitebox}>
                <h2>{item.author} {item.date}</h2>
                <p>{item.comments}</p>
                <a href={item.link}>Link to the Review</a>
              </div>
            </li>
          ))}
        </ul>
      )}
    </section>
  );
}
