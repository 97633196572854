import { useWindowWidth } from '@react-hook/window-size'
import s from './Instructions.module.scss'

export default function Instructions({
  header,
  videoUrl,
  primaryText,
  secondaryText,
  manualLink,
}) {
  const windowWidth = useWindowWidth()

  const handleManualButtonClick = () => {
    window.open(manualLink, '_blank')
  }

  return (
    <section id="instructions" className={s.instructions}>
      <div className={s.container}>
        <div className={s.videoContainer}>
          <iframe title={"Instructions Video"} src={videoUrl} />
        </div>
        <div className={s.textContainer}>
          <h2 className={s.header}>{header}</h2>
          <p className={s.primaryText}>{primaryText}</p>
          {
            windowWidth >= 1280 && (
              <p className={s.secondaryText}>{secondaryText}</p>
            )
          }
          {/* <div className={s.manualButton} onClick={handleManualButtonClick}>
            Manual
          </div> */}
        </div>
      </div>
    </section>
  )
}
