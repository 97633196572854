import React, { useState, useEffect } from 'react';
import s from './Scroll.module.scss';

export default function ScrollProgressIndicator({ sections, fadeIn }) {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [activeSection, setActiveSection] = useState('');



    const handleScroll = () => {
        const totalScrollHeight = document.documentElement.scrollHeight - window.innerHeight;
        const currentScrollPosition = window.scrollY;
        const scrollPercentage = (currentScrollPosition / totalScrollHeight) * 100;
        setScrollPosition(scrollPercentage);

        let currentSection = '';
        sections.forEach((section) => {
            const element = document.getElementById(section.id);
            const scrollPosition = window.scrollY;
            if (element.offsetTop <= scrollPosition && (element.offsetTop + element.offsetHeight) > scrollPosition) {
                currentSection = section.id;
            }
        });
        setActiveSection(currentSection);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const calculateTopOffset = (id) => {
        const element = document.getElementById(id);
        return element ? (element.offsetTop / document.documentElement.scrollHeight) * 100 : 0;
    };

    const goToSection = (id) => {
        const section = document.getElementById(id);
        section.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className={`${s.container} ${fadeIn ? s.fadeIn : ''}`}>
            <div className={s.progressBar}>
                <div className={s.progressFill} style={{ height: `${scrollPosition}%` }} />
                {sections.map((section, index) => (
                    <div key={index} className={s.section} style={{ top: `${calculateTopOffset(section.id)}%` }}>
                        <button
                            className={s.button}
                            onClick={() => goToSection(section.id)}
                            aria-label={`Go to ${section.text}`}
                        />
                        <span
                            className={`${s.label} ${activeSection === section.id ? s.labelOn : s.labelOff}`}
                            onClick={() => goToSection(section.id)}
                        >
                            {section.text}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};