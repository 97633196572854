import { useState, useEffect } from "react";
import s from './ImageGallery.module.scss';

export default function ImageGallery({
  images,
  width = "1000px",
  height = "500px",
  maxWidth = "100%",
  maxHeight = "100%",
  autoSlide = true,
  autoSlideInterval = 4000
}) {
  const [curr, setCurr] = useState(0);
  const galleryStyle = { width, height, maxWidth, maxHeight };

  const prev = () => setCurr(curr => curr === 0 ? images.length - 1 : curr - 1);
  const next = () => setCurr(curr => curr === images.length - 1 ? 0 : curr + 1);

  useEffect(() => {
    if (!autoSlide) return;
    const slideInterval = setInterval(next, autoSlideInterval);
    return () => clearInterval(slideInterval);
  }, [autoSlide, autoSlideInterval, next]);

  return (
    <div className={s.imageGallery} style={galleryStyle}>
      <div className={s.carouselContainer}>
        <div className={s.slidesWrapper} style={{ transform: `translateX(-${curr * 100}%)` }}>
          {images.map((image, index) => (
            <div key={index} className={s.slide}>
              <img src={require('../../../pageContent/img/' + image)} alt={`Slide ${index}`} />
            </div>
          ))}
        </div>
        <div className={s.navigation}>
          <button onClick={prev} className={s.navButton}><span className={s.arrowLeft}>{"<"}</span></button>
          <button onClick={next} className={s.navButton}><span className={s.arrowRight}>{">"}</span></button>
        </div>
        <div className={s.indicators}>
          {images.map((_, i) => (
            <div key={i} className={`${s.indicator} ${curr === i ? s.active : ''}`} />
          ))}
        </div>
      </div>
    </div >
  );
}
