import s from './Downloads.module.scss'

export default function Downloads({ header, downloads }) {
  return (
    <section id="downloads" className={s.downloads}>
      <h2 className={s.header}>{header}</h2>
      <div className={s.downloadsContainer}>
        {downloads.map((download, i) => (
          <div key={i} className={s.downloadModule}>
            <p className={s.instructions}>{download.instructions}</p>
            <img
              className={s.img}
              src={require('../../../pageContent/img/' + download.img)}
              onClick={() => window.open(download.link, '_blank')}
            />
          </div>
        ))}
      </div>
    </section>
  )
}
