// News.js

import React from 'react';
import s from './News.module.scss';

export default function News({ header, news }) {
  return (
    <section className={s.news} id="news">
      <h2 className={s.newsHeader}>{header}</h2>

      {news && news.length > 0 && (
        <ul className={s.newsSection}>
          {news.map((item, index) => (
            <li key={index} className={s.blueBox}>
              <div className={s.newsItemContainer}>
                {/* Include the image and wrap the title with an anchor tag */}
                <a href={item.link} className={s.newsItemLink}>
                  <p className={s.newsItemTitle}>{item.title}</p>
                </a>
              </div>
            </li>
          ))}
        </ul>
      )}
    </section>
  );
}
