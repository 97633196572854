import { HashRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import './styles/reset.scss'
import './styles/globals.scss'

export default function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path='/' element={<Home />} />
      </Routes>
    </HashRouter>
  )
}
